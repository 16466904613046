import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import AddProgram from "@/view/layout/extras/offcanvas/AddProgram.vue";
import ReferralApiService from "@/core/services/api.service.referral";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import moment from "moment/moment";
import VouchersApiService from "@/core/services/api.service.vouchers";
import { VOUCHER_TYPE_LABELS } from "@/view/pages/voucher/voucher-enums";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import CountryService from "@/core/services/country.service";

export default {
  components: { AddProgram, ConfirmDialog },
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "customer-referral-programs",
  data() {
    return {
      editProgramDialogErrors: [],
      voucherLabels: VOUCHER_TYPE_LABELS,
      editProgramDialog: false,
      dataSource: [],
      vouchers: [],
      allVouchers: [],
      vouchersForEdit: [],
      vouchersLoading: false,
      totalPrograms: 0,
      programs: [],
      program: {
        name: "",
        start: null,
        end: null,
        regStart: null,
        regEnd: null,
        numOfRef: null,
        voucherValue: null
      },
      defaultItem: {
        program_name: "",
        start_at: 0,
        end_at: 0,
        joined_before: 0,
        joined_after: 0,
        invite_count: 0,
        award_price: 0
      },
      editedItem: {
        program_name: "",
        start_at: null,
        end_at: null,
        joined_before: null,
        joined_after: null,
        award_count: null,
        invite_count: null,
        description: "",
        invite_period: null,
        voucher_id: "",
        voucher: {},
        countryId: null
      },
      editedIndex: -1,
      requiredRule: [v => !!v || "Field is required"],
      pagination: {
        page: 1,
        itemsPerPage: 50
      },
      pageSizes: [10, 20, 50, 100],
      loading: false,
      countries: [],
      editCountries: [],
      selectedCountry: 0,
      updateCountry: undefined,
      countriesLoading: false
      /*      pageNumber: 0,
      pageSize: 10*/
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getPrograms();
      },
      deep: true
    }
  },
  async created() {
    if (!this.currentUser.superAdmin) return;
    this.loading = true;
    await ReferralApiService.init();
    await VouchersApiService.init();
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    this.selectedCountry = CountryService.getActiveCountry();
    await ReferralApiService.init();
    await SchedulerApiService.init();
    await this.getPrograms();
    await this.getCountries();
    await this.getEditCountries();
    this.retrieveVouchers();
    this.getAlVouchers();
    //    console.log('vauceri', this.vouchers)
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.referralProgram");
    },
    headers() {
      return [
        { text: "ID", align: "start", sortable: false, value: "id" },
        { text: "Program name", value: "programName" },
        { text: this.$i18n.t("common.start"), value: "start", sortable: false },
        { text: this.$i18n.t("common.finish"), value: "end", sortable: false },
        /*        {
          text: "Registration start ",
          value: "registrationStart",
          sortable: false,
        },
        {
          text: "Registration end ",
          value: "registrationEnd",
          sortable: false,
        },*/
        { text: "Days to fulfill offer", value: "days", sortable: false },
        { text: "Users required", value: "minQtty", sortable: false },
        { text: "Vouchers quantity", value: "qtty", sortable: false },
        { text: "Price", value: "price", sortable: false },
        { text: "SUM", value: "sum", sortable: false },
        {
          text: "New users registered",
          value: "newUserReg",
          sortable: false,
          width: "10%"
        },
        { text: "", value: "actions", sortable: false, width: "1%" }
      ];
    }
  },
  methods: {
    convertToLongFormat(formattedDate) {
      const parts = formattedDate.split("-");
      if (parts.length === 3) {
        const day = parseInt(parts[0]);
        const month = this.getMonthIndex(parts[1]);
        const year = parseInt(parts[2]);
        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
          const date = new Date(year, month, day);
          return Math.floor(date.getTime() / 1000);
        }
      }
      return null;
    },
    getMonthIndex(monthName) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      return monthNames.indexOf(monthName);
    },
    async getCountries() {
      this.countriesLoading = true;
      await SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];
          const allCountriesOption = { name: "All", id: "0" };
          this.countries = [allCountriesOption, ...this.countries];
          this.getPrograms();
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    getEditCountries() {
      this.countriesLoading = true;
      return SchedulerApiService.getCountries()
        .then(res => {
          this.editCountries = res.data || [];
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    updateProgram(item) {
      const program = {
        name: item.program_name,
        description: item.description,
        start_at: Date.parse(item.start_at) / 1000,
        end_at: Date.parse(item.end_at) / 1000,
        joined_before: Date.parse(item.joinedBefore) / 1000,
        joined_after: Date.parse(item.joinedAfter) / 1000,
        invite_count: +item.invite_count,
        voucher_id: item.voucher.id,
        award_count: +item.award_count,
        invite_period: +item.invite_period,
        countryId: +this.updateCountry.id
      };
      console.log(item, program);
      return ReferralApiService.updateProgram(this.editedItem.id, program).then(response => {
        this.$log.debug("responss", response);
        this.hideEditProgramDialog(item);
        this.getPrograms();
      });
    },
    editItem(item) {
      console.log(this.vouchers);
      this.editedIndex = this.programs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      const format = "YYYY-MM-DD HH:mm:ss";
      console.log(this.editedItem);

      this.editedItem.start_at = this.editedItem.start_at
        ? moment(this.editedItem.start_at * 1000).format(format)
        : null;

      this.editedItem.end_at = this.editedItem.end_at
        ? moment(this.editedItem.end_at * 1000).format(format)
        : null;

      this.editedItem.joinedBefore = this.editedItem.joinedBefore
        ? moment(this.editedItem.joinedBefore * 1000).format(format)
        : null;

      this.editedItem.joinedAfter = this.editedItem.joinedAfter
        ? moment(this.editedItem.joinedAfter * 1000).format(format)
        : null;

      const selCountry = this.editCountries.find(country => country.id === item.countryId);
      const voucherForEdit = this.allVouchers.find(voucher => voucher.id === item.voucher_id);
      this.vouchersForEdit = [voucherForEdit, ...this.vouchers];

      if (selCountry) {
        this.updateCountry = selCountry;
      }

      if (!this.editedItem.voucher) {
        this.editedItem.voucher = {};
      }

      this.editedItem.voucher.id = item.voucher_id;
      console.log("editditm", this.editedItem);

      this.editProgramDialog = true;
    },

    retrieveVouchers() {
      const params = this.getVoucherParams();
      this.vouchersLoading = true;
      return new Promise(resolve => {
        this.loading = true;
        VouchersApiService.query("", params)
          .then(response => {
            this.vouchers = response.data.result;
            this.vouchers = this.vouchers.filter(voucher => voucher.referenceId === null);
            this.loading = false;
            resolve();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.vouchersLoading = false));
      });
    },
    getAlVouchers() {
      const params = this.allVouchersParams();
      this.vouchersLoading = true;
      return new Promise(resolve => {
        this.loading = true;
        VouchersApiService.query("", params)
          .then(response => {
            this.allVouchers = response.data.result;
            this.loading = false;
            resolve();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.vouchersLoading = false));
      });
    },

    hideEditProgramDialog() {
      this.editProgramDialog = false;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.programs[this.editedIndex], this.editedItem);
      } else {
        this.programs.push(this.editedItem);
      }
      this.close();
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete " + item.name + "?"
        )
      ) {
        await this.deleteProgram(item);
      }
    },
    deleteProgram(item) {
      this.loading = true;
      if (item.id != null) {
        ReferralApiService.deleteProgram(item.id)
          .then(response => {
            this.$log.debug("Deleted program: ", item.id);
            this.$log.debug("Response: ", response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.getPrograms(), (this.loading = false)));
      }
    },
    getVoucherParams() {
      const params = {};

      params["PageNumber"] = this.pagination.page - 1;
      params["PageSize"] = this.pagination.itemsPerPage;
      params["voucherTypes"] = "VoucherForFriends";
      //   params["referenceTypes"] = "Referral";
      params["referenceIds"] = "null";

      return params;
    },
    allVouchersParams() {
      const params = {};
      params["PageNumber"] = this.pagination.page - 1;
      params["PageSize"] = this.pagination.itemsPerPage;
      params["voucherTypes"] = "VoucherForFriends";
      return params;
    },
    getRequestParams() {
      const params = {};

      params["page"] = this.pagination.page - 1;
      params["size"] = this.pagination.itemsPerPage;
      params["countryId"] = this.selectedCountry.id;

      return params;
    },
    reloadPrograms() {
      console.log("are u loading?");
      this.getPrograms();
    },
    getPrograms() {
      this.loading = true;
      const params = this.getRequestParams();
      //  console.log(params);
      return ReferralApiService.getReferralProgramsList(params)
        .then(response => {
          this.programs = response.data;
          this.totalPrograms = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.loading = false;
          this.programs = [];
        })
        .finally(() => (this.loading = false));
    },
    formatDate(unixTimestamp) {
      if (unixTimestamp) {
        const date = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ];

        const formattedDate = `${this.padZero(date.getDate())}-${
          monthNames[date.getMonth()]
        }-${date.getFullYear()}`;

        return formattedDate;
      } else {
        return "";
      }
    },
    padZero(value) {
      return value < 10 ? `0${value}` : value;
    },
    getCountryName() {
      return this.countries.find(country => country?.id === this.selectedCountry)?.name;
    },
    copyProgram(item) {
      console.log(item);
      const program = {
        name: item.program_name,
        start_at: item.start_at,
        end_at: item.end_at,
        joined_before: item.joinedBefore,
        joined_after: item.joinedAfter,
        invite_count: item.invite_count,
        award_price: item.award_price,
        affiliate_count: item.affiliate_count,
        award_count: item.award_count,
        invite_period: item.invite_period,
        description: item.description,
        published: item.published
      };
      ReferralApiService.createProgram(program)
        .then(response => {
          this.$log.debug("Program created: " + response);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.isSaving = false;
          this.getPrograms();
        });
    }
  }
};
