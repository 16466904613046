export const VOUCHER_TYPES = {
  SHOPPING_VOUCHER: "ShoppingVoucher",
  // VOUCHER_FOR_FRIENDS: "VoucherForFriends",
  VOUCHER_FOR_TWO: "VoucherForTwo"
};

export const VOUCHER_TYPE_LABELS = {
  [VOUCHER_TYPES.SHOPPING_VOUCHER]: "Standard Voucher",
  [VOUCHER_TYPES.VOUCHER_FOR_TWO]: "Voucher for Two"
  //[VOUCHER_TYPES.VOUCHER_FOR_FRIENDS]: "Voucher for Friends"
};

export const PRODUCT_TYPES = {
  NEW_COLLECTION: "NEW_COLLECTION",
  SHOPPING_WITH_FRIENDS: "SHOPPING_WITH_FRIENDS",
  SHOPPING_EVENT: "SHOPPING_EVENT",
  SWF_FICT: "SWF_FICT",
  PRODUCT_POST: "PRODUCT_POST"
};

export const PRODUCT_TYPE_LABELS = {
  [PRODUCT_TYPES.NEW_COLLECTION]: "New collection",
  [PRODUCT_TYPES.SHOPPING_WITH_FRIENDS]: "Shopping with friends",
  [PRODUCT_TYPES.SHOPPING_EVENT]: "Shopping event",
  [PRODUCT_TYPES.SWF_FICT]: "SWF fict",
  [PRODUCT_TYPES.PRODUCT_POST]: "Product post"
};
