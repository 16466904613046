<template>
  <div class="topbar-item">
    <div class="d-flex flex-row-reverse">
      <v-btn id="kt_add_program_toggle" color="green darken-1" class="ma-2 white--text p-4">
        <v-icon dark>mdi-plus</v-icon>
        ADD PROGRAM
      </v-btn>
    </div>
    <div id="add_program" ref="add_program" class="offcanvas offcanvas-right p-5">
      <div class="d-flex flex-row-reverse">
        <a
          id="kt_add_program_close"
          href="#/referral/programs"
          class="btn btn-xs btn-icon btn-light btn-hover-primary kt_add_program_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <div class="offcanvas-header d-flex align-items-center pb-15">
        <h3 class="m-0">New program for <strong>"Voucher for Friends"</strong></h3>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validateAndSave">
        <v-text-field
          v-model="program.name"
          :label="$t('common.programName')"
          :rules="requiredRule"
        ></v-text-field>
        <v-textarea
          v-model="program.description"
          :label="$t('common.description')"
          rows="4"
        ></v-textarea>
        <div class="d-flex justify-content-between gap">
          <v-datetime-picker
            v-model="dateStartFormatted"
            :label="$t('commonTable.promoStart')"
            :rules="requiredRule"
            time-format="HH:mm:ss"
          >
          </v-datetime-picker>
          <v-datetime-picker
            v-model="dateEndFormatted"
            :label="$t('commonTable.promoFinish')"
            time-format="HH:mm:ss"
            :rules="requiredRule"
          >
          </v-datetime-picker>
        </div>
        <div class="d-flex justify-content-between gap">
          <v-datetime-picker
            v-model="regDateStartFormatted"
            :label="$t('commonTable.userRegStart')"
            :rules="requiredRule"
            time-format="HH:mm:ss"
          >
          </v-datetime-picker>
          <v-datetime-picker
            v-model="regDateEndFormatted"
            :label="$t('commonTable.userRegFinish')"
            time-format="HH:mm:ss"
            :rules="requiredRule"
          >
          </v-datetime-picker>
        </div>
        <div class="d-flex justify-content-between gap">
          <v-text-field
            v-model="program.inviteCount"
            type="number"
            :label="$t('commonTable.numOfRef')"
            :rules="requiredRule"
          ></v-text-field>
          <v-autocomplete
            label="Voucher"
            :items="vouchers"
            v-model="program.voucherValue"
            :loading="vouchersLoading"
            item-text="title"
            item-value="id"
            return-object
            class="width"
            :rules="requiredRule"
          >
            <template v-slot:selection="{ item }">
              {{ item ? item.description : null }}
            </template>
            <template v-slot:item="{ item }">
              <div class="voucher">
                <div class="vo-title">
                  {{ item.description }}
                  <sapn> {{ item.price }}&euro;</sapn>
                </div>
              </div>
            </template>
          </v-autocomplete>
          <!--          <v-text-field
            v-model="program.voucherValue"
            type="number"
            :label="$t('commonTable.voucherValue')"
            :rules="requiredRule"
          ></v-text-field>-->
        </div>
        <div class="d-flex justify-content-between gap">
          <v-text-field
            v-model="program.awardCount"
            type="number"
            :label="$t('commonTable.numOfVouchers')"
            :rules="requiredRule"
          ></v-text-field>
          <v-text-field
            v-model="program.invitePeriod"
            type="number"
            :label="$t('commonTable.offerTime')"
            :rules="requiredRule"
          ></v-text-field>
        </div>

        <div class="d-flex btn-gap justify-content-end pt-5">
          <v-btn @click="closeOffcanvas()">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="primary" class="mr-4" type="submit">
            <!-- :disabled="isSaving"-->
            {{ $t("common.save") }}
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#add_program {
  overflow: hidden;
}
.offcanvas {
  width: 530px;
}
.offcanvas.offcanvas-right {
  right: -530px;
}
.offcanvas.offcanvas-right.offcanvas-on {
  right: 0 !important;
}
.gap {
  gap: 29px;
  .v-text-field .v-label {
    overflow: visible;
    text-overflow: initial;
  }
}
.btn-gap {
  gap: 41px;
}
.btn-cancel {
  width: 85px;
  height: 36px;
  border-radius: 4px;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}
.width {
  width: 175px !important;
}
.voucher {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  border-bottom: 0.5px solid lightgray;
  margin: 0.4rem 0;
  // padding: 0.5rem;
  hr {
    margin: 0;
  }
  .vo-title {
    padding: 0;
    font-size: 1.1rem;
    display: flex;
    justify-content: space-between;
  }
}
</style>

<script>
import KTLayoutAddProgram from "@/assets/js/layout/extended/add-program.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import VouchersApiService from "@/core/services/api.service.vouchers";
import { VOUCHER_TYPE_LABELS } from "@/view/pages/voucher/voucher-enums";
import ReferralApiService from "@/core/services/api.service.referral";
import CountryService from "@/core/services/country.service";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "AddProgram",
  data() {
    return {
      voucherLabels: VOUCHER_TYPE_LABELS,
      loading: false,
      vouchers: [],
      vouchersLoading: false,
      valid: false,
      isSaving: false,
      program: {
        name: "",
        start: null,
        end: null,
        regStart: null,
        regEnd: null,
        inviteCount: null,
        description: "",
        awardCount: null,
        invitePeriod: null
      },
      requiredRule: [v => !!v || "Field is required"],
      pagination: {
        page: 1,
        itemsPerPage: 500
      },
      pageSizes: [10, 20, 50, 100],
      selectedCountry: null
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getPrograms();
      },
      deep: true
    }
  },
  async created() {
    this.loading = true;
    await VouchersApiService.init();
    this.selectedCountry = CountryService.getActiveCountry();
    this.vouchersApiInitiated = true;
    this.retrieveVouchers();
  },
  mounted() {
    KTLayoutAddProgram.init(this.$refs["add_program"]);
    if (localStorage.language) {
      this.language = localStorage.language;
    }
  },
  methods: {
    addProgram() {
      this.$emit("programAdded");
    },
    previousState() {
      this.$router.go(0);
    },
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveProgram();
      }
    },
    saveProgram() {
      const program = {
        name: this.program.name,
        start_at: this.dateStartFormatted,
        end_at: this.dateEndFormatted,
        joined_before: this.regDateStartFormatted,
        joined_after: this.regDateEndFormatted,
        invite_count: +this.program.inviteCount,
        voucher_id: this.program.voucherValue.id,
        award_count: +this.program.awardCount,
        invite_period: +this.program.invitePeriod,
        description: this.program.description,
        countryId: this.selectedCountry
      };

      ReferralApiService.createProgram(program)
        .then(response => {
          this.$log.debug("Program created: " + response);
          this.$emit("programAdded");
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.isSaving = false;
          this.closeOffcanvas();
        });
    },
    closeOffcanvas() {
      this.resetForm();
      new KTOffcanvas(KTLayoutAddProgram.getElement()).hide();
    },
    resetForm() {
      this.program = {
        name: "",
        start: null,
        end: null,
        regStart: null,
        regEnd: null,
        inviteCount: null,
        awardPeriod: null,
        description: "",
        awardCount: null
      };

      this.dateStartFormatted = null;
      this.dateEndFormatted = null;
      this.regDateStartFormatted = null;
      this.regDateEndFormatted = null;

      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    retrieveVouchers() {
      const params = this.getRequestParams();
      this.vouchersLoading = true;
      return new Promise(resolve => {
        this.loading = true;
        VouchersApiService.query("", params)
          .then(response => {
            this.vouchers = response.data.result;
            this.vouchers = this.vouchers.filter(voucher => voucher.referenceId === null);
            this.loading = false;
            resolve();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.vouchersLoading = false));
      });
    },
    getRequestParams() {
      const params = {};

      params["PageNumber"] = this.pagination.page - 1;
      params["PageSize"] = this.pagination.itemsPerPage;
      params["voucherTypes"] = "VoucherForFriends";
      //   params["referenceTypes"] = "Referral";
      params["referenceIds"] = null;

      return params;
    }
  },
  computed: {
    dateStartFormatted: {
      get() {
        if (this.program.start == null || this.program.start === "") return null;
        return Date.parse(this.program.start) / 1000;
      },
      set(value) {
        this.program.start = value;
      }
    },
    dateEndFormatted: {
      get() {
        if (this.program.end == null || this.program.end === "") return null;
        return Date.parse(this.program.end) / 1000;
      },
      set(value) {
        this.program.end = value;
      }
    },
    regDateStartFormatted: {
      get() {
        if (this.program.regStart == null || this.program.regStart === "") return null;
        return Date.parse(this.program.regStart) / 1000;
      },
      set(value) {
        this.program.regStart = value;
      }
    },
    regDateEndFormatted: {
      get() {
        if (this.program.regEnd == null || this.program.regEnd === "") return null;
        return Date.parse(this.program.regEnd) / 1000;
      },
      set(value) {
        this.program.regEnd = value;
      }
    }
  }
};
</script>
