import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

/**
 * Service to call HTTP request via Axios
 */
const ReferralApiService = {
  URL: "",
  async init() {
    Vue.use(VueAxios, axios);
    const resp = await Vue.axios.get(`api/url/get-base-uri`);
    this.URL = resp.data;
  },
  API_REFERRAL: "api/user/v1/referral/",
  API_REFERRAL_PROGRAM: "api/user/v1/referral/program",
  API_REFERRAL_UPDATE_PROGRAM: "api/user/v1/referral/program/",
  API_REFERRAL_PROGRAMS: "api/user/v1/referral/program/programs",
  API_REFERRAL_INITIATORS: "api/user/admin/referral/initiators",
  /**
   * Send the GET HTTP request
   * @param params
   * @returns {*}
   */ async getReferralList(params) {
    const config = {
      params
    };

    try {
      return await Vue.axios.get(this.URL + this.API_REFERRAL, config);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ReferralApiService ${error}`);
    }
  },
  async getReferralProgramsList(params) {
    const config = {
      params
    };
    try {
      return await Vue.axios.get(this.URL + this.API_REFERRAL_PROGRAM, config);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ReferralApiService ${error}`);
    }
  },
  async getReferralsList(params) {
    const config = {
      params
    };
    try {
      return await Vue.axios.get(
        this.URL + this.API_REFERRAL_INITIATORS,
        config
      );
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ReferralApiService ${error}`);
    }
  },
  async getAllReferralPrograms() {
    try {
      return await Vue.axios.get(this.URL + this.API_REFERRAL_PROGRAMS);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ReferralApiService ${error}`);
    }
  },
  createProgram(params) {
    try {
      return Vue.axios.post(this.URL + this.API_REFERRAL_PROGRAM, params);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ReferralApiService ${error}`);
    }
  },
  async getProgramById(programId) {
    try {
      return await Vue.axios.get(this.URL + this.API_REFERRAL_PROGRAM + programId);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ReferralApiService ${error}`);
    }
  },
  async updateProgram(programId, params) {
    try {
      return await Vue.axios.patch(this.URL + this.API_REFERRAL_UPDATE_PROGRAM + programId, params);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ReferralApiService ${error}`);
    }
  },
  async deleteProgram(programId) {
    try {
      return await Vue.axios.delete(this.URL + this.API_REFERRAL_PROGRAM + programId);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ReferralApiService ${error}`);
    }
  },
  async query(resource, params) {

    try {
      return await Vue.axios.get(this.URL + resource, params);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ReferralApiService ${error}`);
    }
  },
};

export default ReferralApiService;
